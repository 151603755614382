import React from "react";
import IMG0 from "../../assets/zhernusApp.png";
import IMG1 from "../../assets/linktree.png";
import IMG2 from "../../assets/weatherapp.png";
import IMG3 from "../../assets/todo.png";
import IMG4 from "../../assets/zorsht.png";
import IMG5 from "../../assets/quotes.png";
import IMG6 from "../../assets/locationAndMap.png";
import IMG7 from "../../assets/discordBot.jpg";
import "./portfolio.css";

const Portfolio = () => {
  const soloProjects = [
    {
      id: 0,
      title: "Zhernus App",
      img: IMG0,
      description:
        "A Desktop application that translates English movie subtitle into kurdish language",
      technologies: "Electron | React | Type script | Tailwind css",
      link: "https://zhernus.sarband.online/",
      github: "https://github.com/SarbandAkray/ZhernusApp",
    },
    {
      id: 1,
      title: "Quotes App",
      img: IMG5,
      description:
        "A Desktop application that provides quotes and with notifications to keep you motivated",
      technologies: "Electron | HTML | SCSS | JS",
      link: "https://tree.sarband.online/",
      github: "https://github.com/SarbandAkray/QuotesApp",
    },
    {
      id: 2,
      title: "Linktree",
      img: IMG1,
      description:
        "A website that contains links to all of your social media pages",
      technologies: "HTML | SCSS",
      link: "https://tree.sarband.online/",
      github: "https://github.com/SarbandAkray/one-link-to-all-your-socials",
    },
    {
      id: 3,
      title: "Weather App",
      img: IMG2,
      description:
        "Weather app that shows users the weather and temperature according to their location",
      technologies: "React",
      link: "https://myweather.sarband.online/",
      github: "https://github.com/SarbandAkray/weather-project",
    },
    {
      id: 4,
      title: "To-Do App",
      img: IMG3,
      description: "User friendly app to plan your day and manage tasks",
      technologies: "HTML | CSS | JavaScript ",
      link: "https://todo.sarband.online/",
      github: "https://github.com/SarbandAkray/smallProj/tree/main",
    },
    {
      id: 5,
      title: "Zorsht App",
      img: IMG4,
      description:
        "Fully developed mobile application that gives quotes to the users with notifications and with categories",
      technologies: "flutter",
      link: "https://play.google.com/store/apps/details?id=com.zorsht",
      github: "https://github.com/SarbandAkray",
    },
    {
      id: 6,
      title: "Location and Map",
      img: IMG6,
      description:
        "A website that provides location and map infromation of your location in real-time",
      technologies: "HTML | CSS | JavaScript",
      link: "https://mylocation.sarband.online/",
      github: "https://github.com/SarbandAkray/locationAndmap",
    },
    {
      id: 6,
      title: "Discord Music Bot (Spike)",
      img: IMG7,
      description:
        "A fully working Discord music bot called Spike that connects to the server channels audio and plays music from youtube according to the users input to the bot",
      technologies: "Python",
      link: "https://github.com/SarbandAkray/discord-spike-bot",
      github: "https://github.com/SarbandAkray/discord-spike-bot",
    },
  ];

  return (
    <section id="portfolio">
      <h5>$cat Works.txt</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {soloProjects.map((pro) => (
          <article className="portfolio__item" key={pro.id}>
            <div className="portfolio__item-image">
              <img src={pro.img} alt={pro.title} />
            </div>
            <div className="portfolio__item-content">
              <h3>{pro.title}</h3>
              <p>{pro.description}</p>
              <p>{pro.technologies}</p>
            </div>
            <div className="portfolio__item-cta">
              <a
                href={pro.github}
                target="_blank"
                className="btn"
                rel="noreferrer"
              >
                GitHub
              </a>
              <a
                href={pro.link}
                target="_blank"
                className="btn btn-primary"
                rel="noreferrer"
              >
                Visit Website
              </a>
            </div>
          </article>
        ))}
      </div>
    </section>
  );
};

export default Portfolio;
