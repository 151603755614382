import React from "react";
import { BsLinkedin } from "react-icons/bs";
import { FaGithub, FaInstagram, FaTiktok } from "react-icons/fa";

const HeaderSocials = () => {
  return (
    <div className="header__socials">
      <a
        href="https://www.linkedin.com/in/sarband-akray-7a5b44207/"
        target="_blank"
        rel="noreferrer"
      >
        <BsLinkedin />
      </a>
      <a
        href="https://github.com/sarbandAkray"
        target="_blank"
        rel="noreferrer"
      >
        <FaGithub />
      </a>
      <a
        href="https://instagram.com/sarband_codes"
        target="_blank"
        rel="noreferrer"
      >
        <FaInstagram />
      </a>
      <a
        href="https://tiktok.com/@sarband_codes"
        target="_blank"
        rel="noreferrer"
      >
        <FaTiktok />
      </a>
    </div>
  );
};

export default HeaderSocials;
