import React from "react";

const CTA = () => {
  return (
    <div className="cta">
      <a href={"https://tree.sarband.online"} className="btn">
        Linktree
      </a>
      <a href="#contact" className="btn btn-primary">
        contact me
      </a>
    </div>
  );
};

export default CTA;
